import useWindowDimensions from "../../Service/WindowDimension";

const buttonStyle = {
    padding: "15px 40px",
    borderRadius: 30,
    border: 0,
    fontFamily: "Roboto",
    fontWeight: 700,
    background: "#EBC10D",
    color: "#FFF",
    cursor: "pointer",
    maxHeight: 54,
    display: "inline-flex"
};


const logoStyle = {
    height: 40,
    position: "relative",
    top: -8,
    left: -8
};

const logoStyleBig = {
    height: 42,
    position: "relative",
    top: -2,
    left: -17
}

const BLACK_COLOR = "black";
const BLUE_COLOR = "blue";
const NONE = "none";
const ORANGE = "orange";

const handleButtonColor = (background, small, big, width) => {
    let newBgColor = {}, buttonSize = {};

    if (background === BLACK_COLOR) {
        newBgColor.background = "#212121";
    }

    if (background === BLUE_COLOR) {
        newBgColor.background = "#5265F2";
    }

    if (background === NONE) {
        newBgColor.background = "transparent";
    }

    if (background === ORANGE) {
        newBgColor.background = "#b74b04";
    }

    if (small) {
        buttonSize.maxHeight = 40
        buttonSize.fontFamily = "Roboto";
        buttonSize.fontStyle = "normal";
        buttonSize.fontWeight = "bold";
        buttonSize.fontSize = 14;
        buttonSize.padding = "10px 25px";
    }

    if (big) {
        buttonSize.alignItems = "center";
        buttonSize.fontSize = 32;
        if (width > 600) {
            buttonSize.padding = "40px 60px";
        } else {
            buttonSize.padding = "40px 30px";
            buttonSize.fontSize = 25;
        }

        buttonSize.borderRadius = 185
    }

    return Object.assign(
        {},
        buttonStyle,
        buttonSize,
        newBgColor
    );
}

const Button = props => {
    const { width } = useWindowDimensions();

    return (
        <button style={handleButtonColor(props.background, props.small, props.big, width)} onClick={props.onClick}>
            {props.icon && <img src={props.icon} alt={""} style={props.big ? logoStyleBig : logoStyle}/>}
            <span>
                {props.children}
            </span>
        </button>
    );
};

export default Button;