import AutoCarousel from "../components/Carousel/Carousel";
import "./MeetOurBears.css";

const containerStyle = {
    height: 1100,
    background: "#303030",
    color: "#FFF"
};

const textContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0px 10px",
    marginBottom: 25,
    marginTop: 40
};

const titleStyle = {
    fontWeight: 700,
    fontSize: 72,
    fontFamily: "Roboto",
    marginBottom: 10
};

const contentStyle = {
    paddingTop: 25,
    fontSize: 23,
    fontWeight: 400,
    maxWidth: 1000,
    fontFamily: "Roboto",
};

const carouselStyle = {
    height: 600,
    overflow: "hidden",
    display: "contents",
};

const MeetOurBears = () => {
    return (
        <div className="MeetOurBearsContainer" style={containerStyle}>
            <div style={{paddingTop: 50, paddingBottom: 20}}>
            <span style={titleStyle}>
                    Meet our Bears
            </span>
            </div>

            <div style={carouselStyle}>
                <AutoCarousel />
            </div>

            <div style={textContainer}>
                <span style={contentStyle} className={"meet-ours-bears-text-xyz"}>
                    Take a look, the evil teddy bears look spooky and cool! The team has a long experience working with videogame art and made sure to bring senior concept and illustration knowledge to each one of the 150+ traits.
                </span>
            </div>
        </div>
    )
};

export default MeetOurBears;