import bg from "./bg.png"
import bgm from "./bgm.png";
import KidGif from "../components/KidGif/KidGif";
import "./CreepyKids.css";
import ClaimYourCompanionButton from "../components/Button/ClaimYourCompanionButton";
import useWindowDimensions from "../Service/WindowDimension";


const blockStyle = {
    height: 832,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}

const headerStyle = {
    paddingTop: 100,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 72,
    color: "white",
    marginLeft: 10,
    marginRight: 10
}

const spanStyle = {
    color: "white",
    maxWidth: 1000,
    fontSize: 26,
    fontWeight: 500,
    paddingTop: 50,
    marginLeft: 10,
    marginRight: 10
}

const bottomStyle = {
    marginTop: 30
}

const fyiContent = {
    marginTop: 25,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 18,
    color: "white"
}

function CreepyKids() {
    const { width } = useWindowDimensions(), url = width && width > 0 && width < 750 ? bgm : bg;

    const bgStyle = {
        backgroundImage: "url("+url+")",
        backgroundSize: "cover"
    }

    const style = Object.assign({}, blockStyle, bgStyle);

    return (
        <div className={"kidsBack"} style={style}>
            <h1 style={headerStyle} className={"creepy-kids-title-xyz"}>
                Creepy Kids
            </h1>
            <div style={spanStyle}>
            <span className={"creepy-kids-text-xyz"}>
                Who would love to receive an Evil Teddy Bear at christimas? A Creepy Kid of course. So here they are! The official ETBC companions are here for you to claim. With 80+ unique traits make sure to grab one for each ETBC you own. Have fun!
            </span>

            </div>
            <div style={{marginTop: 30}}>
                <KidGif />
            </div>
            <div style={bottomStyle}>
                <ClaimYourCompanionButton big={ width >= 600 } />
            </div>
            <div style={fyiContent}>
                *gas+upkeep fee apply
            </div>
        </div>
    )
}


export default CreepyKids;