import React from "react";
import "./hide.css";

const questionStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 30,
    transition: "all ease 0.1s",
    cursor: "pointer"
}

const answerStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    display: "flex",
    paddingRight: 20
}


const hrStyle = {
    color: "#DEDEDE",
    border: "2px solid #DEDEDE",
    marginRight: 10,
    marginLeft: 10
}

const iconStyle = {
    cursor: "pointer",
    marginRight: 10
};

const yellowBarStyle = {
    background: "yellow",
    flex: "0 0 3px",
    marginRight: 20,
    marginLeft: 5
}

class Question extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: false}
    }

    onClick() {
        this.setState({open: !this.state.open});
    }

    render() {
        const props = this.props, open = this.state.open, iconText = open? "- " : "+";

        return (
            <div>
                <div style={questionStyle} className={"question"} onClick={() => this.onClick()}>
                    <span style={iconStyle}>
                        {iconText}
                    </span>
                    {props.question}
                </div>
                <div style={answerStyle} className={open ? "answer show" : "answer"}>
                    <div style={yellowBarStyle}/>
                    <div>{props.children}
                    </div>
                </div>
                <div>

                </div>
                <hr style={hrStyle} />
            </div>
        )
    }
}

export default Question;