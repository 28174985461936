const iconStyle = {
    height: 30,
    marginRight: 15,
    cursor: "pointer"
}

function Icon(props) {
    const style = Object.assign(
        {},
        iconStyle,
        props.style
    );

    return (
        <a href={props.link} target={"_blank"} rel="noreferrer">
            <img src={props.src} alt={""} style={style} />
        </a>
    )
}

export default Icon;