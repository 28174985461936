import discordLogo from "./ImgSource/discordLogo.png";
import twitterLogo from "./ImgSource/twitterLogo.png";
import unkLogo from "./ImgSource/unkLogo.png";
import logo from "../TopBlock/logo.png";
import "./Footer.css";
import texture from "./ImgSource/texture";
import useWindowDimensions from "../Service/WindowDimension";

const containerStyle = {
    height: 150,
    backgroundImage: "url("+texture+")"
};

const buttonContainerStyle = {
    paddingTop: 15
};

const logoContainerStyle = {
    marginTop: 0
};


const pointerStyle = {
    cursor: "pointer"
};

const msgFooterStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    width: "100%"
}

const imgLogoStyle = Object.assign({
    marginLeft: 20
}, pointerStyle);

const Footer = () => {
    const {width} = useWindowDimensions();

    const marginImg = width > 600 ? 20 : 0;

    return (
        <div style={containerStyle}>
            <div style={buttonContainerStyle}>
            </div>
            <div style={logoContainerStyle}>
                <a href={"https://discord.gg/etbc"} rel="noreferrer" target={"_blank"}>
                    <img
                        height={35}
                        style={pointerStyle}
                        alt={""}
                        src={discordLogo}/>
                </a>
                <a href={"https://twitter.com/EvilTeddysNFT"} rel="noreferrer" target={"_blank"}>
                    <img height={35} style={imgLogoStyle} alt={""} src={twitterLogo}/>
                </a>
                <a href={"https://opensea.io/collection/etbc"} rel="noreferrer" target={"_blank"}>
                    <img height={35} style={imgLogoStyle} alt={""} src={unkLogo}/>
                </a>
            </div>
            <div className={"msgFooter-xyz"} style={msgFooterStyle}>

                    <img height={90} alt={""} src={logo} style={{paddingRight: marginImg}}/>

                    © 2021 Evil Teddy Bear, all rights reserved. Made With Love.

                    <div style={{width: 90, paddingLeft: 20}}/>
                </div>

        </div>
    );
};

export default Footer;
