import React from "react";
import Modal from "../Modal/Modal";
import Button from "./Button";
import RedeemModal from "../Modal/ReedemModal";

class   RedeemEvilButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false}
        this.modal = this.modal.bind(this);
    }


    modal(show) {
        if (show) {
            return (
                <Modal>
                    <RedeemModal
                      price={1000}
                      onClose={() => this.setState({show: false})}/>
                </Modal>
            );
        }
        return null;
    }

    render() {
        return (
            <div>
                <Button
                  background={"none"}
                    onClick={() => this.setState({show: true})}>
                    $EVIL
                </Button>
                {this.modal(this.state.show)}
            </div>
        )
    }
}

export default RedeemEvilButton;