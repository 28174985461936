import React from "react";
import Modal from "../Modal/Modal";
import MintModal from "../Modal/MintModal";
import Button from "./Button";

class ClaimYourCompanionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false}
        this.modal = this.modal.bind(this);
    }


    modal(show) {
        if (show) {
            return (
                <Modal>
                    <MintModal
                        title={"Mint Your Creepy Kid"}
                        serviceValue={"creepy"}
                        price={"0.0"}
                        onClose={() => this.setState({show: false})}/>
                </Modal>
            );
        }
        return null;
    }

    render() {
        return (
            <div>
                <Button small={this.props.small}
                        big={this.props.big}
                    onClick={() => this.setState({show: true})}>
                    Claim Your Companion
                </Button>
                {this.modal(this.state.show)}
            </div>
        )
    }
}

export default ClaimYourCompanionButton;