import image from "./giff-creepy.gif";
import free from "./free.png";
import "./KidGif.css";

const imgStyle = {
    height: 210,
    width: 210,
    borderRadius: 15
}

const imgSmallStyle = {
    height: 160,
    width: 160,
    borderRadius: 15
}

const containerStyle = {
    position: "relative"
}

const freeStyle = {
    position: "absolute",
    top: -30,
    right: -30,
    height: 75,
    width: 75
}

function KidGif(props) {
    const style = props.small ? imgSmallStyle : imgStyle;

    return (
        <div style={containerStyle} className={"KigGif-Container"}>
            <img src={free} alt="" style={freeStyle} />
            <img src={image} alt="" style={style} />
        </div>
    )
}

export default KidGif;