import xIcon from "./X_icon.png";

const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "100vh",
    width: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    zIndex: 10
}

const containerStyle = {
    display: "block",
    width: 575,
    height: 500,
    background: "#212121",
    borderRadius: 10,
    color: "white"
}

const titleStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 36,
    paddingBottom: 40
}

const closeContainerStyle = {
    right: 0,
    width: "100%",
    textAlign: "right",
    padding: 10
}

const closeStyle = {
    height: 43,
    width: 43,
    cursor: "pointer"
}

function getCloseButton(hide, onClose) {
    if (hide) {
        return <div style={{height: 43,padding: 10}} />
    }
    return <img style={closeStyle} src={xIcon} alt={""} onClick={onClose} />
}

function ModalViewRedeem(props) {
    return (
        <div style={modalStyle} onClick={props.onClose}>
            <div style={containerStyle} onClick={(evt) => evt.stopPropagation()}>
                <div style={closeContainerStyle}>
                    {getCloseButton(props.hideClose, props.onClose)}
                </div>
                <div style={titleStyle}>
                    {props.title}
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default ModalViewRedeem;