import Button from "../Button/Button";
import React from "react";
import Loading from "./Loading";
import {claim, connectMetamask, getBalanceCoinUser, getNFTFromWallet, mintWithEvil} from "../../Service/Connector";
import ModalViewRedeem from "./ModalViewRedeem";

const inputStyle = {
  background: "#111111",
  borderRadius: 5,
  border: 0,
  width: 74,
  height: 60,
  color: "white",
  textAlign: "center",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 37,
  margin: 5
}

const buttonStyle = {
  background: "#111111",
  borderRadius: 5,
  border: 0,
  width: 36,
  height: 60,
  color: "white",
  textAlign: "center",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 37,
  margin: 5,
  cursor: "pointer",
  userSelect: "none"
}

const userInteractionStyle = {
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center"
}

const obsStyle = {
  color: "white",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: 12,
  paddingTop: 5
}

const priceStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 20
}

const priceStyleBalance = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 20,
  padding: "15px"
}

class RedeemModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      valueAvailable: 0,
      error: "",
      balance: 0,
      ids: [],
      tabActive: 0,
      wallet: "",
      value: 1,
      isConnected: false
    }
    this.mintWithEvil = this.mintWithEvil.bind(this);
    this.claim = this.claim.bind(this);
    this.getValues = this.getValues.bind(this);
    this.connectWallet = this.connectWallet.bind(this);
  }

  connectWallet() {
    connectMetamask().then(async (object) => {
      if (object.success === true) {
        this.setState({isConnected: true, wallet: object.msg.slice(0, 8) + '...'});
        await this.getValues();
      } else {
        this.setState({error: object.msg})
        setTimeout(() => {
          this.setState({error: ""})
        },5000)
      }
    })
  }

  async getValues() {
    getBalanceCoinUser().then((object) => {
      this.setState({balance: object.value});
    })

    getNFTFromWallet().then((object) => {
      this.setState({valueAvailable: object.value, ids: object.ids});
    })
  }

  async claim() {
    this.setState({processing: true});

    const temp = await claim(this.state.ids), newState = {processing: false};

    if (!temp.success) {
      newState.error = temp.msg
    }
    await this.getValues();
    this.setState(newState);
  }

  async mintWithEvil() {
    this.setState({processing: true});

    const temp = await mintWithEvil(this.state.value), newState = {processing: false};

    if (!temp.success) {
      newState.error = temp.msg
    }
    await this.getValues();
    this.setState(newState);

  }

  changeTabMint() {
    this.setState({tabActive: 1});
  }

  changeTabRedeem() {
    this.setState({tabActive: 0});
  }

  handleInputChange(value) {
    this.setState({tempValue: value})
  }

  handleBlur() {
    this.handleChange(this.state.tempValue);
  }

  handleChange(value) {
    const newState = {tempValue: null};

    if (!isNaN(value) && value >= 1 && value * 1000 < this.state.balance) {
      newState.value = value
    }

    this.setState(newState);
  }

  controlChange(positive) {
    const value = positive ? this.state.value + 1 : this.state.value - 1;

    this.handleChange(value);
  }

  getTotalPrice(unit, price) {
    return (unit * price);
  }

  getBody(processing) {
    if (processing) {
      return <div style={{marginTop: 10, position: "relative"}}>
        <Loading/>
      </div>
    }
    let tabRedeem = <div>
      <div style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        gap: "10px",
        paddingBottom: "20px",
        paddingLeft: "50px",
        flexDirection: "column"
      }}>
        <div style={priceStyle}>
          Wallet: {this.state.wallet}
        </div>
        <div style={priceStyle}>
          Teddys: {this.state.ids ? this.state.ids.length : 0}
        </div>
        <div style={priceStyle}>
          Balance: {this.state.balance}
        </div>
        <div style={priceStyle}>
          Available to Redeem: {this.state.valueAvailable ? this.state.valueAvailable : 0}
        </div>
      </div>
      {this.state.valueAvailable > 0 ? <Button onClick={this.claim}>Redeem</Button> : ""}

      <div style={obsStyle}>
        <br/>{this.state.error}
      </div>
    </div>

    let tabs = <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      paddingBottom: "40px"
    }}>

      <Button background={this.state.tabActive === 0 ? "orange" : ""}
              onClick={() => {
                this.changeTabRedeem()
              }}>Redeem&nbsp;Tab</Button>
      <Button background={this.state.tabActive === 1 ? "orange" : ""}
              onClick={() => {
                this.changeTabMint()
              }}>Mint&nbsp;Tab</Button>
    </div>

    let connect = <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection:"column",
      gap: "10px",
      paddingBottom: "40px"
    }}>
      <Button onClick={() => {
        this.connectWallet()
      }}>Connect Wallet</Button>
      <div style={obsStyle}>
        <br/>{this.state.error}
      </div>
    </div>

    let tabMint = <div>
      <div style={priceStyleBalance}>
        Balance: {this.state.balance}
      </div>
      <div style={userInteractionStyle}>

        <div style={{display: "flex"}}>
          <div
            onClick={() => this.controlChange(false)}
            style={buttonStyle}>
            -
          </div>
          <input
            type={"text"}
            style={inputStyle}
            value={this.state.tempValue ?? this.state.value}
            onChange={(evt) => this.handleInputChange(evt.target.value)}
            onBlur={() => this.handleBlur()}
            autoFocus/>
          <div
            onClick={() => this.controlChange(true)}
            style={buttonStyle}>
            +
          </div>
        </div>
        <div style={priceStyle}>
          Total: {this.getTotalPrice(this.state.value, this.props.price)}EVIL
        </div>
      </div>
      <div style={{paddingTop: 20}}>
        <Button onClick={this.mintWithEvil}>Mint</Button>
      </div>
      <div style={obsStyle}>
        <br/>{this.state.error}
      </div>
    </div>
    return (
      <div>
        {!this.state.isConnected ? connect : tabs}
        {(this.state.tabActive === 0 && this.state.isConnected) ? tabRedeem : ''}
        {(this.state.tabActive === 1 && this.state.isConnected) ? tabMint : ''}
      </div>
    )
  }

  handleClose(processing, onClose) {
    if (!processing) {
      onClose()
    }
  }

  render() {
    const processing = this.state.processing,
      title = processing ? "Accept the transaction on your wallet" : this.props.title,
      onClose = this.props.onClose;

    return (
      <ModalViewRedeem
        title={title}
        onClose={() => this.handleClose(processing, onClose)}
        hideClose={processing}
      >
        {this.getBody(this.state.processing)}
      </ModalViewRedeem>
    )
  }
}

export default RedeemModal;
