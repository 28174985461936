import ModalView from "./ModalView";
import Button from "../Button/Button";
import React from "react";
import Loading from "./Loading";
import {buy} from "../../Service/Connector";

const inputStyle = {
    background: "#111111",
    borderRadius: 5,
    border: 0,
    width: 74,
    height: 60,
    color: "white",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 37,
    margin: 5
}

const buttonStyle = {
    background: "#111111",
    borderRadius: 5,
    border: 0,
    width: 36,
    height: 60,
    color: "white",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 37,
    margin: 5,
    cursor: "pointer",
    userSelect: "none"
}

const userInteractionStyle = {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center"
}

const obsStyle = {
    color: "white",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    paddingTop: 5
}

const priceStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 27
}

class MintModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {processing: false, value: 1, error: ""}
        this.onClick = this.onClick.bind(this);
    }

    async onClick() {
        this.setState({processing: true});

        const temp = await buy(this.props.serviceValue, this.state.value), newState = {processing: false};

        if (!temp.success) {
            newState.error = temp.msg
        }

        this.setState(newState);
    }

    handleInputChange(value) {
        this.setState({tempValue: value})
    }

    handleBlur() {
        this.handleChange(this.state.tempValue);
    }

    handleChange(value) {
        const newState = {tempValue: null};

        if (!isNaN(value) && value <= 20 && value >= 1) {
            newState.value = value
        }

        this.setState(newState);
    }

    controlChange(positive) {
        const value = positive ? this.state.value + 1 : this.state.value - 1;

        this.handleChange(value);
    }

    getTotalPrice(unit, price) {
        return (unit * price).toFixed(2);
    }

    getBody(processing) {
        if (processing) {
            return <div style={{marginTop: 10, position: "relative"}}>
                <Loading />
            </div>
        }
        return (
            <div>
                <div style={userInteractionStyle}>
                    <div style={{display: "flex"}}>
                        <div
                            onClick={() => this.controlChange(false)}
                            style={buttonStyle}>
                            -
                        </div>
                        <input
                            type={"text"}
                            style={inputStyle}
                            value={this.state.tempValue ?? this.state.value}
                            onChange={(evt) => this.handleInputChange(evt.target.value)}
                            onBlur={() => this.handleBlur()}
                            autoFocus />
                        <div
                            onClick={() => this.controlChange(true)}
                            style={buttonStyle}>
                            +
                        </div>
                    </div>
                    <div style={priceStyle}>
                        Total: {this.getTotalPrice(this.state.value, this.props.price)}ETH
                    </div>
                </div>
                <div style={{paddingTop: 20}}>
                    <Button onClick={this.onClick}>Mint</Button>
                </div>
                <div style={obsStyle}>
                    20 limit per transaction
                    <br />{this.state.error}
                </div>
            </div>
        )
    }

    handleClose(processing, onClose) {
        if(!processing) {
            onClose()
        }
    }

    render() {
        const processing = this.state.processing,
            title = processing ? "Accept the transaction on your wallet" : this.props.title,
            onClose = this.props.onClose;

        return (
            <ModalView
                title={title}
                onClose={() => this.handleClose(processing, onClose)}
                hideClose={processing}
            >
                {this.getBody(this.state.processing)}
            </ModalView>
        )
    }
}

export default MintModal;
