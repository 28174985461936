import './App.css';
import MeetOurBears from "./MeetOurBears/MeetOurBears";
import CreepyKids from "./CreepyKids/CreepyKids";
import RoadMap from "./RoadMap/RoadMap";
import FAQ from "./FAQ/FAQ";
import WhatIs from "./WhatIs/WhatIsV2";
import TopBlock from "./TopBlock/TopBlock";
import Footer from "./Footer/Footer";
import {_CreepyContract, totalSupply} from "./Service/Connector";
import React from "react";
import TechnicalBlock from "./TechnicalBlock/TechnicalBlock";
import GoTopButton from "./components/Button/GoTopButton";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {totalSold: 0, totalKids: 0, showTop: false};
        this.onScroll = this.onScroll.bind(this);
    }

    onScroll() {
            this.setState({showTop: document.body.scrollTop > 50 || document.documentElement.scrollTop > 50});
    }

    async componentDidMount() {
        window.addEventListener("scroll", this.onScroll);
        const valueKids = await totalSupply(_CreepyContract)

        this.setState({totalSold: 6666, totalKids: valueKids});
    }

    getGoTopButton() {
        const style = {
            position: "fixed",
            bottom: 10,
            right: 10,
            zIndex: 999
        }

        if (this.state.showTop) {
            return (
                <GoTopButton>
                    <div style={style}>
                        <div
                            onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                            className={"topButton"} style={{userSelect: "none"}}>
                            TOP
                        </div>
                    </div>
                </GoTopButton>
            )
        }

        return null;
    }

    render() {
        return (
            <div className="App">
                <TopBlock
                    totalSold={this.state.totalSold}
                    totalKids={this.state.totalKids}
                />
                <WhatIs/>
                <MeetOurBears/>
                <CreepyKids/>
                <RoadMap totalSold={this.state.totalSold}/>
                <TechnicalBlock />
                <FAQ />
                <Footer/>
                {this.getGoTopButton()}
            </div>
        );
    }

}

export default App;
