const bodyStyle = {
    border: "1px solid #EBC10D",
    boxSizing: "border-box",
    borderRadius: 8,
    width: 165,
    marginTop: 5,
    marginRight: 15,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 20,
    color: "white",
    textAlign: "center"
}

const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20
}

const headerStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 18,
    color: "white"
}

const headerSmallStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 12,
    color: "white"
}

const bodySmallStyle = {
    border: "1px solid #EBC10D",
    boxSizing: "border-box",
    borderRadius: 8,
    width: 70,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "white",
    textAlign: "center"
}

function HeaderCard(props) {
    const headerFinalStyle = props.small ? headerSmallStyle : headerStyle;
    const bodyFinalStyle = props.small ? bodySmallStyle : bodyStyle;

    return (
        <div style={containerStyle}>
            <div style={headerFinalStyle}>
                {props.title}
            </div>
            <div style={bodyFinalStyle}>
                {props.children}
            </div>
        </div>
    )
}

export default HeaderCard;