import pentool from "./pentool.png"
import security from "./security.png";
import noclash from "./noclash.png";
import InfoCard from "./InfoCard";
import React from "react";

const containerStyle = {
    minHeight: 835,
    backgroundColor: "#303030",
    paddingBottom: 150
}

const cardsStyle = {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    flexWrap: "wrap",
    marginTop: 45
}

const headerStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontsize: 72,
    color: "white",
    paddingTop: 100,
    margin: "0px 10px"
}

function TechnicalBlock() {
    return (
        <div style={containerStyle}>
            <h1 style={headerStyle}>
                Technical and Security Info
            </h1>

            <div style={cardsStyle}>
                <InfoCard
                    icon={pentool}
                    title={"150+ possible traits"}>
                    Each Evil Teddy Bear is a one of a kind NFT generated from 150+ possible traits, from body,
                    bottom, top, expressions and background. The possibilities are many!
                </InfoCard>
                <InfoCard
                    icon={security}
                    title={"Proof of Authenticity"}>
                    VERIFIED SMART CONTRACT ADDRESS:
<br /><br />
                    <div style={{lineBreak: "anywhere"}}>
                        0x4840f2182109539aCbfef535BB6dEA2D560b9D70
                    </div>
                </InfoCard>
                <InfoCard
                    icon={noclash}
                    title={"No bonding curve"}>
                    There are 6,666 Evil Teddy Bears with no bonding curve, each bear costs 0.03 ETH + gas.
                    <br />Activations require Metamask.
                </InfoCard>
            </div>
        </div>
    )
}

export default TechnicalBlock;