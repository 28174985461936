import KidGif from "../components/KidGif/KidGif";
import HeaderCard from "./HeaderCard";
import ClaimYourCompanionButton from "../components/Button/ClaimYourCompanionButton";
import "./KidBlock.css";

const containerStyle = {
    justifyContent: "center",
    display: "flex"
}

const headerStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 26,
    color: "white",
    textAlign: "left"
}

const textBlockStyle = {
    textAlign: "left",
    marginLeft: 30
}

const descriptionStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    color: "white"
}

const cardButtonStyle = {
    display: "flex",
    alignItems: "end",
}

const footerStyle = {
    color: "white"
}

const kidGifContainerStyle = {
    flex: "0 1 auto",
    display: "flex",
    justifyContent: "center",
}

function KidBlock(props) {
    return (
        <div className={"kidBlock"} style={containerStyle}>
            <div className={"KidGifContainer"} style={kidGifContainerStyle}>
                <KidGif small/>
            </div>
            <div style={textBlockStyle} className={"kidBlockText-Margin"}>
                <div style={{maxWidth:400}} className={"kidBlock-Text"}>
                    <h3 style={headerStyle}>Creepy Kids</h3>
                    <span
                        className={"kidBlock-description"}
                        style={descriptionStyle}>
                        Who would love to receive an Evil Teddy Bear
                        at christmas? A Creepy Kid of course.
                    </span>
                </div>
                <div className={"kidBlock-HeaderCard"} style={cardButtonStyle}>
                    <div style={{display: "flex"}}>
                        <HeaderCard
                            small
                            title={"Minted Kids"}>
                            {props.total}
                        </HeaderCard>
                        <div style={{width: 10}} />
                        <HeaderCard
                            small
                            title={"Price"}>
                            FREE
                        </HeaderCard>
                    </div>
                    <div className={"spaceBetwCardAndButton"}>
                        <div style={{width: 10}} />
                    </div>
                    <div>
                        <ClaimYourCompanionButton small />
                    </div>
                </div>
                <div style={footerStyle} className={"kidBlock-footer"}>
                    *gas+upkeep fee apply
                </div>
            </div>
        </div>
    )
}

export default KidBlock;