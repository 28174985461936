import "./Achievement.css";

const percentageDefaultStyle = {
    minWidth: 87,
    height: 87,
    color: "white",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    marginRight: 25
}

const blockStyle = {
    display: "flex",
    margin: "10px 10px",
    alignItems: "center"
}

const textStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 18,
    color: "white",
    width: 525,
    textAlign: "left"
}

function Achievement(props) {
    const color = props.actualPercentage >= props.percentage ? "#EB350D" : "#303030";

    const percentageStyle = Object.assign({}, percentageDefaultStyle, {backgroundColor: color});

    return (
        <div style={blockStyle} className={"achievement-xyz"}>
            <div style={percentageStyle} className={"achievement-percentage-xyz"}>
                {props.percentage}%
            </div>
            <div style={textStyle} className={"achievement-content-xyz"}>
                {props.children}
            </div>
        </div>
    )
}

export default Achievement;