import SwiperCore, { EffectCoverflow, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import TeddyBear from "./ImgSource/TeddyBear.png";
import TeddyBear2 from "./ImgSource/TeddyBear2.png";
import TeddyBear3 from "./ImgSource/TeddyBear3.png";
import TeddyBear4 from "./ImgSource/TeddyBear4.png";
import TeddyBear5 from "./ImgSource/TeddyBear5.png";
import TeddyBear6 from "./ImgSource/TeddyBear6.png";
import TeddyBear7 from "./ImgSource/TeddyBear7.png";
import TeddyBear8 from "./ImgSource/TeddyBear8.png";
import TeddyBear9 from "./ImgSource/TeddyBear9.png";

import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "./Carousel.css";

SwiperCore.use([EffectCoverflow, Autoplay]);

const AutoCarousel = () => {
    return (
        <Swiper
            autoplay={{
                delay: 1500,
                disableOnInteraction: false
            }}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            loop
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true
            }}
            pagination={true}
            className={"CarouselSwiper"}
            height={600}
        >
            <SwiperSlide>
                <img alt={""} src={TeddyBear}/>
            </SwiperSlide>
            <SwiperSlide>
                <img alt={""} src={TeddyBear3}/>
            </SwiperSlide>
            <SwiperSlide>
                <img alt={""} src={TeddyBear2}/>
            </SwiperSlide>
            <SwiperSlide>
                <img alt={""} src={TeddyBear4}/>
            </SwiperSlide>
            <SwiperSlide>
                <img alt={""} src={TeddyBear5}/>
            </SwiperSlide>
            <SwiperSlide>
                <img alt={""} src={TeddyBear6}/>
            </SwiperSlide>
            <SwiperSlide>
                <img alt={""} src={TeddyBear7}/>
            </SwiperSlide>
            <SwiperSlide>
                <img alt={""} src={TeddyBear8}/>
            </SwiperSlide>
            <SwiperSlide>
                <img alt={""} src={TeddyBear9}/>
            </SwiperSlide>
        </Swiper>
    );
};

export default AutoCarousel;