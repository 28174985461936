import Question from "./Question";
import faqImage from "./Faq.png";
import "./FAQ.css";

const headerStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 72,
    marginTop: 30,
    marginBottom: 15
}

const blockStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FFF"
}

const imgContainerStyle = {
    padding: "0 60px"
}

const imgStyle = {
    height: 700,
}

const bodyStyle = {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    alignItems: "start"
}

const questionBlockStyle = {
    width: "100%",
    textAlign: "left"
}

function FAQ() {
    return (
        <div style={blockStyle}>
            <div style={headerStyle}>
                FAQ
            </div>
            <div style={bodyStyle} className={"faq-content-xyz"}>
                <div style={imgContainerStyle} className={"faq-logo-container"}>
                    <img alt="" style={imgStyle} src={faqImage} className={"faq-logo-xyz"}/>
                </div>
                <div style={questionBlockStyle}>
                    <Question question={"How many bears there will be?"}>
                        6.666 Bears
                    </Question>
                    <Question question={"How can I get one?"}>
                        Here on our website you can click on “mint your bears” button. And soon you’ll be
                        able to buy at opensea.io.
                    </Question>
                    <Question question={"How can I trade my Evil Teddy Bear?"}>
                        On OpenSea, you must click on the sell button and configure how you wanna sell it.
                    </Question>
                    <Question question={"What's Opensea?"}>
                        Opensea is the largest NFT market in the entire Ethereum Blockchain opensea.io
                    </Question>
                    <Question question={"When I'll be able to see the different properties and rarity details?"}>
                        We will list in Rarity Tools and Rarity Sniper & also release a rarity map soon!
                    </Question>
                    <Question question={"Does owning a Evil Teddy Bear NFT give commercial use rights?"}>
                        Your Teddy Bear looks freaking awesome and you want to use it commercially?
                        <br /><br />Of course you can, that’s what NFT’s are all about. Share with us your derivatives!
                    </Question>
                    <Question question={"What does the future hold for Evil Teddy Bears?"}>
                        With serious concern for the longevity of the project we have a post-sale roadmap that adresses
                        long term concerns.
                        <br /><br />We are gonna always be in chase for effective actions to keep the community
                        fully engaged.
                    </Question>
                    <Question question={"Any other questions?"}>
                        Come talk with us!&nbsp;

                        <a href={"https://discord.gg/etbc"} target="_blank" rel={"noreferrer"}>
                            https://discord.gg/etbc
                        </a>

                        <br />We don’t bite often.
                    </Question>
                </div>
            </div>
        </div>
    )
}

export default FAQ;