import bg from "./bg.png";
import whatIsPng from "./whatis.png";
import React from "react";
import useWindowDimensions from "../Service/WindowDimension";

const blockStyle = {
    height: 760,
    background: "url("+bg+")",
    display: "flex",
    justifyContent: "center",
    flexGrow: "1"
}

const headerStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 60,
    marginTop: 15
}

const subTitleStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 32,
    color: "#EBC10D"
}

const bodyTextStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 19,
    width: "100%",
    display: "flex",
    justifyContent: "center"
}

const wrapperStyle = {
    marginTop: 200,
    position: "absolute",
    boxShadow: "-19px 16px 0px #EBC10D",
    borderRadius: 22,
    marginLeft: 200,
    marginRight: 200,
    backgroundColor: "white",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
}

const imgStyle = {
    position: "absolute",
    left: -190
}

const textBody = {
    minWidth: 920
}

const wrapperTextStyle = {
    width: 500,
    marginBottom: 25
}

const imageSizeStyle = {
    width: 370
}

function WhatIsV2() {
    const {width} = useWindowDimensions();
    let computedHeaderStyle = Object.assign({}, headerStyle);
    let computedSubTitleStyle = Object.assign({}, subTitleStyle);
    let computedBodyTextStyle = Object.assign({}, bodyTextStyle)
    let computedImgStyle = Object.assign({}, imgStyle);
    let computedTextBody = Object.assign({}, textBody)
    let computedBlockStyle = Object.assign({}, blockStyle);
    let computedWrapperStyle = Object.assign({}, wrapperStyle)
    let computedWrapperTextStyle = Object.assign({}, wrapperTextStyle);
    let computedImageSizeStyle = Object.assign({}, imageSizeStyle);

    if (980 < width && width  < 1280) {
        computedHeaderStyle = Object.assign({}, computedHeaderStyle, {fontSize: 45})
        computedSubTitleStyle = Object.assign({},computedSubTitleStyle, {fontSize: 27})
        computedBodyTextStyle = Object.assign({}, computedBodyTextStyle, {fontSize: 20})
        computedImgStyle = Object.assign({}, computedImgStyle, {left: -130})
        computedWrapperTextStyle = Object.assign({}, wrapperTextStyle, {width: 350});
    }

    if (width <= 980) {
        computedHeaderStyle = Object.assign({}, computedHeaderStyle, {fontSize: 37})
        computedSubTitleStyle = Object.assign({},computedSubTitleStyle, {fontSize: 20})
        computedBodyTextStyle = Object.assign({}, computedBodyTextStyle, {fontSize: 14})
        computedImgStyle = Object.assign({}, imgStyle, {left: "50%",
            transform: "translateX(-50%)", top: -50});
        computedTextBody = Object.assign({}, textBody, {minWidth: 150, marginTop: 290, padding: "0 60px"});
        computedHeaderStyle = Object.assign({}, computedHeaderStyle, {marginTop: 20})
        computedBlockStyle = Object.assign({}, computedBlockStyle, {height: 1000});
        computedWrapperStyle = Object.assign({}, computedWrapperStyle, {marginLeft: 20, marginRight: 20})
        computedImageSizeStyle = Object.assign({}, computedImageSizeStyle, {width: 300})
        computedWrapperTextStyle = Object.assign({}, wrapperTextStyle, {width: 200});
    }



    return (
            <div style={computedBlockStyle} className={"whais-container"}>
                <div style={computedWrapperStyle}>
                    <div style={computedImgStyle}>
                        <img src={whatIsPng} alt={""} style={computedImageSizeStyle}/>
                    </div>
                    <div style={computedTextBody}>
                        <h1 style={computedHeaderStyle}>
                            Hey, nice to meet you
                        </h1>
                        <div style={computedSubTitleStyle}>
                            I don't bite often
                        </div>
                        <br />
                        <div style={computedBodyTextStyle}>
                            <div style={computedWrapperTextStyle}>
                                For those who love what's cute and creepy, these 6.666 Evil Teddy Bears were hand sewn to
                                meet the ever
                                increasing expectations for high quality designs of the NFT environment.
                                <br /><br />Each one is a unique digital collectible based on the Ethereum Blockchain as an
                                ERC-721 Token.
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
}

export default WhatIsV2;