import "./InfoCard.css";

const containerStyle = {
    backgroundColor: "#232323",
    height: 350,
    color: "white",
    maxWidth: 315,
    /* Sombra */

    boxShadow: "0px 5px 10px rgba(89, 89, 89, 0.15)",
    borderRadius: 10,
    margin: 30
}

const iconStyle = {
    width: 60,
    paddingTop: 30
}

const headerStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 23
}

const bodyStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 18,
    padding: "30px 30px"
}

function InfoCard(props) {
    return (
        <div style={containerStyle} className={"info-card-xyz"}>
            <div>
                <div>
                    <img src={props.icon} alt={""} style={iconStyle}/>
                </div>
                <div style={headerStyle} className={"info-card-title-xyz"}>
                    {props.title}
                </div>
            </div>
            <div style={bodyStyle} className={"info-card-content-xyz"}>
                {props.children}
            </div>
        </div>
    )
}

export default InfoCard;