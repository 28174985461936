import Achievement from "./Achievement";
import React from "react";
import "./RoadMap.css";
import bg from "./bg.png";

const blockStyle = {
    minHeight: 1300,
    backgroundColor: "#212121",
    display: "flex",
    flexFlow: "Wrap",
    justifyContent: "center",
    backgroundImage: "url("+bg+")"
}

const headerStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontsize: 72,
    color: "white",
    paddingTop: 100
}

const achievementsBlock = {
    display: "flex",
    flexDirection: "column",
    flexFlow: "wrap",
    paddingTop: 15,
    justifyContent: "center",
    width: "100%"
}

function RoadMap(props) {

    const totalSold = props.totalSold/6666 * 100;

    return (
        <div style={blockStyle} className={"road-map-xyz"}>
            <h1 style={headerStyle} className={"road-map-header-xyz"}>
                RoadMap
            </h1>
            <div style={achievementsBlock}>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={0}>
                    We will be airdropping 50 Evil Teddy Bears To Early Discord Members!
                </Achievement>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={10}>
                    We will be adding the Rarity Sniper
                </Achievement>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={20}>
                    We will Airdrop 50 Lucky Evil Teddy Bear owners a random mint
                    5 Lucky Teddy Bear owners Will Receive 0.5 Ethereum sent directly to their wallet.
                    The Creepy Kids Companions are available for all Teddy owners, 1 companion per teddy to be minted for free (Gas + upkeep apply)
                </Achievement>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={30}>
                    10 Lucky Evil Teddy Bear owners will be picked at random to receive a custom framed HD print of their Teddy Bear!
                </Achievement>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={40}>
                    Project roadmap and web site update
                    10% of the primary sales, and 10% of the secondary secondary being placed in the community wallet and used for giveaways, community events hosting
                </Achievement>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={50}>
                    1 golden ticket will be given to two lucky Evil Teddy Bear owners owning at least a bear and a companion, giving them the possibility to design their own 1-1 Teddy!
                </Achievement>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={60}>
                    1 Lucky Evil Teddy Bear owners will get the chance to participate in a live drawing and  interact with the artist in the creation of their own teddy, selecting the traits they would like to have*
                </Achievement>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={70}>
                    3 Lucky Evil Teddy Bear owners will receive 5 freshly minted teddies and 1 companion!
                    We will add the rarity tools
                </Achievement>
                <Achievement
                    actualPercentage={totalSold}
                    percentage={80}>
                    We will be airdropping 5 more custom HD Print to 5 lucky Evil Teddy Bear owners
                    10 Lucky Evil Teddy Bear owners will receive 1 Teddy plus HD miniature picture of teddy of choice in their collection
                </Achievement>
                <Achievement
                  actualPercentage={totalSold}
                  percentage={100}>
                    Roadmap 3.0 at ETBC sellout
                </Achievement>
            </div>
        </div>
    )
}

export default RoadMap;
