import logo from "./logo.png";
import React from "react";
import Button from "../components/Button/Button";
import twitter from "./twitter.png";
import opensea from "./opensea.png";
import discord from "./discord.png";
import Icon from "./Icon";
import HeaderCard from "./HeaderCard";
import BackgroundCarousel from "../components/Carousel/BackgroundCarousel";
import KidBlock from "./KidBlock";
import "./TopBlock.css";
import RedeemEvilButton from "../components/Button/RedeemEvilButton";

const blockStyle = {
    minHeight: 1040,
}

const headerStyle = {
    paddingTop: 50,
    display: "flex",
    alignItems: "center"
}

const blockImgStyle = {
    marginLeft: 100,
    marginRight: "auto"
}

const logoStyle = {
    width: 107,
    height: 107
}

const menuStyle = {
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "end",
    display: "flex",
    alignItems: "center",
    marginRight: 100
}

const titleStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 90,
    color: "white"
}

const titleBlockStyle = {
    paddingTop: 100
}

const buttonTopMintStyle = {
    paddingTop: 20
}

const headerCardContainerStyle = {
    display: "flex",
    justifyContent: "center"
}

const kidBlockStyle = {
    paddingTop: 110
}

const hideStyle = {
    display: "none"
};

const backgroundHeader = {
    opacity: 0,
    position: "fixed",
    height: 110,
    width: "100%",
    background: "black",
    zIndex: 2
};

const logoMobileStyle = {
    maxWidth: 50,
    maxHeight: 50,
    marginTop: 50,
    right: 30,
    position: "fixed",
    zIndex: 3,
    cursor: "pointer"
};

const iconMobileStyle = {
    width: 35,
    height: 35,
    marginTop: 50,
    position: "fixed",
    top: 8,
    zIndex: 3,
    cursor: "pointer"
};

const iconTwitterMobileStyle = {
    width: 35,
    height: 35,
    marginTop: 50,
    position: "fixed",
    top: 8,
    zIndex: 3,
    cursor: "pointer"
}

const iconOpenSeaMobileStyle = {
    width: 35,
    height: 35,
    marginTop: 50,
    position: "fixed",
    top: 8,
    zIndex: 3,
    cursor: "pointer"
};

const iconEvil = {
    width: 35,
    height: 35,
    marginTop: 40,
    position: "fixed",
    top: 8,
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#FFF",
    left: 110,
    zIndex: 3,
    cursor: "pointer"
};

const debounce = (fn) => {
    let frame;

    return (...params) => {
        if (frame) {
            cancelAnimationFrame(frame);
        }

        frame = requestAnimationFrame(() => {
            fn(...params);
        });
    }
};

const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
}

document.addEventListener('scroll', debounce(storeScroll), { passive: true });

storeScroll();

function TopBlock(props) {
    return (
        <div style={blockStyle}>
            <div style={hideStyle} className={"mobile-header-xyz"}>
                <Icon style={iconMobileStyle} src={discord} link={"https://discord.gg/etbc"}/>
                <Icon style={iconTwitterMobileStyle} src={twitter} link={"https://twitter.com/EvilTeddysNFT"}/>
                <Icon style={iconOpenSeaMobileStyle} src={opensea} link={"https://opensea.io/collection/etbc"}/>
                <div style={iconEvil}><RedeemEvilButton/></div>
                <img style={logoMobileStyle} className={"mobile-logo-xyz"} src={logo} alt="" />
                <div style={backgroundHeader} className={"mobile-header-bg-xyz"}/>
            </div>
            <div style={headerStyle} className={"top-block-header-xyz"}>
                <div style={blockImgStyle} className={"top-block-logo-xyz"}>
                    <img style={logoStyle} src={logo} alt="" />
                </div>
                <div style={menuStyle} className={"top-block-menu-xyz"}>
                    <Button onClick={() => window.scrollTo(0, 0)}>Home</Button>
                    <RedeemEvilButton />
                    <Button
                        background={"none"}
                        onClick={() => {
                            window.scrollTo(0,
                                document.querySelector(".whais-container").offsetTop
                            )
                        }}
                    >Overview</Button>
                    <Button
                        background={"none"}
                        onClick={() => {
                            window.scrollTo(0,
                                document.querySelector(".road-map-xyz").offsetTop
                            )
                        }}
                    >Roadmap</Button>
                    <Icon src={discord} link={"https://discord.gg/etbc"} />
                    <Icon src={twitter} link={"https://twitter.com/EvilTeddysNFT"} />
                    <Icon src={opensea} link={"https://opensea.io/collection/etbc"} />
                </div>
            </div>
            <div style={titleBlockStyle} className={"top-block-title-xyz"}>
                <h1 style={titleStyle}>Meet the</h1>
                <h1 style={titleStyle}>Evil Teddy Bears!</h1>
            </div>
            <div style={buttonTopMintStyle}>
                <div style={{color:"white",    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: 60}}>SOLD OUT</div>
            </div>
            <div>
                <div style={headerCardContainerStyle} className={"kidsBlock-counter"}>
                    <HeaderCard title={"Minted Bears"}><b>6666</b>/6666</HeaderCard>
                    <HeaderCard title={"Price"}>0.03ETH</HeaderCard>
                </div>
            </div>
            <div
                style={kidBlockStyle}
                className={"kidBlock-parent"}>
                <KidBlock total={props.totalKids}/>
            </div>
            <BackgroundCarousel />
        </div>
    )
}

export default TopBlock;
