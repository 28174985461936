import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import img1 from "./ImgSource/img1.png";
import img2 from "./ImgSource/img2.png";
import img3 from "./ImgSource/img3.png";
import img4 from "./ImgSource/img4.png";
import img5 from "./ImgSource/img5.png";

import "swiper/swiper.min.css";
import "./BackgroundCarousel.css";

const containerStyle = {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -2,
    opacity: 1
}

const opacityBgStyle = {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
}

SwiperCore.use([Autoplay]);

class BackgroundCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            background: "#434343"
        };

        this.onSlideChange = this.onSlideChange.bind(this);
        this.getContainerStyle = this.getContainerStyle.bind(this);
    }

    onSlideChange(inst) {
        const bgColor = inst.realIndex % 2 === 1 ? "#B45DEF" : "#5951E4";

        this.setState({
            background: bgColor
        });
    }

    getContainerStyle() {
        return Object.assign(
            {},
            containerStyle,
            {
                background: this.state.background
            }
        );
    }

    render() {
        return (
            <div style={this.getContainerStyle()}>
                <div style={opacityBgStyle}/>
                <div style={{position: "relative"}}>
                    <Swiper
                        centeredSlides={true}
                        height={757}
                        autoplay={{
                            "delay": 3500,
                            "disableOnInteraction": false
                        }}
                        className="BackgroundCarouselSwiper"
                        onSlideChange={this.onSlideChange}
                        loop
                    >
                        <SwiperSlide>
                            <img alt={""} style={{backgroundColor: "67,67,67"}} src={img1}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img alt={""} src={img2}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img alt={""} src={img3}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img alt={""} src={img4}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img alt={""} src={img5}/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        );
    }
};

export default BackgroundCarousel;