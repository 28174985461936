import "./Loading.css"

function Loading() {
    return (
        <div className={"spinner"}>

        </div>
    )
}

export default Loading;