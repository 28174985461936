import Web3 from "web3";
import axios from 'axios'
import etbcAbi from './etbcAbi.json'
import redemptionAbi from './redemptionAbi.json'
import saleAbi from './saleAbi.json'
import sale2Abi from './sale2Abi.json'
import coinAbi from './coinAbi.json'
// export const _bearsContract = "0x4840f2182109539aCbfef535BB6dEA2D560b9D70";
// export const _CreepyContract = "0x0E6BD1b518bAF831806Da123E1Feac7F9eDd7E99";
// const chainIdValid = 1; //MAINNET
//const chainIdValidName = 'mainnet';
// const saleAddressBears = "0xc34e88a9c34be87740a4b1F375be615964a238cb"
// const saleAddressCreepy = "0xdACF63A030bA491E0BF7bb6bF2188C5583027bBf"

// export const _bearsContract = "0x4D4DCe80c71e349077915d4C2A467A7BbA5AdF47";
// export const _CreepyContract = "0x0E6BD1b518bAF831806Da123E1Feac7F9eDd7E99";
// const chainIdValid = 4; //MAINNET
// const chainIdValidName = 'rinkeby'; //MAINNET
// const saleAddressBears = "0xbb92FAeb0c095FCa183f28609Ca05CE93f2A87B1"
// const saleAddressBears2 = "0x9981c6A763645181f642F42AE4C9dFC3Ad350250"
// const saleAddressCreepy = "0xdACF63A030bA491E0BF7bb6bF2188C5583027bBf"
// const coin = "0xF04499c650C6f732118c0965618BAAaBc4EbD3f4"
// const redemption = "0x8f924d6f57ec550C554c8711db659F9203106aa2";

export const _bearsContract = "0x4840f2182109539aCbfef535BB6dEA2D560b9D70";
export const _CreepyContract = "0x0E6BD1b518bAF831806Da123E1Feac7F9eDd7E99";
const chainIdValid = 1; //MAINNET
const chainIdValidName = 'mainnet'; //MAINNET
const saleAddressBears = "0x9B4C5865896706d0777a45648Bb6F4F4e6b7b46D"
const saleAddressBears2 = "0x8f997F42d9CF15e3EeE8dA19Ec3E25981749C947"
const saleAddressCreepy = "0xdACF63A030bA491E0BF7bb6bF2188C5583027bBf"
const coin = "0x1Ec4E31Fd6d1a80d312b19E93fEE0A611ACdC04c"
const redemption = "0x7983eD0b10a6449D22ad4e96c6C1eF71Bb0255a5";

const creepyRedemptionAbi = [
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "numberOfCreepyKidsToMint",
        "type": "uint256"
      }
    ],
    "name": "mintNFT",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "fee",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "creepyKidsAsk",
        "type": "uint256"
      }
    ],
    "name": "numberOfEligibleEvilTeddyBearInAccount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "eTeddys",
        "type": "uint256"
      },
      {
        "internalType": "uint256[]",
        "name": "eTeddysIds",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
]

export const connectMetamask = async () => { //getWeb3
  try {
    if (window.ethereum) {
      await window.ethereum.send('eth_requestAccounts');
      window.web3 = new Web3(window.ethereum);
      return {
        success: true,
        msg: await getAccount()
      };
    }

    return {
      success: false,
      msg: "You don't have Metamask plugin installed"
    };
  }
  catch (e) {
    if (e.message.includes("wallet_requestPermissions")) {
      return {
        success: false,
        msg: "You already have one solicitation on your Wallet"
      };
    }

    return {
      success: false,
      msg: "Something goes wrong!"
    };
  }
};

// "bear" pra bear, creepy pra creepy
export const buy = async (bearOrCreppy, qtd) => {
  const statusConnected = await connectMetamask();
  if (!statusConnected.success) {
    return statusConnected;
  }

  try {
    if (window.web3 && window.web3.eth) {
      const chainId = await window.web3.eth.net.getId();
      if (chainId !== chainIdValid) {
        return {success: false, msg: "Please use Ethereum Mainnet"};
      } else {
        let qtdMint = parseInt(qtd);
        if (qtdMint > 0 && qtdMint <= 20) {
          let account = await getAccount();

          if (!account) {
            return {
              success: false,
              msg: "There is no account connected!"
            };
          }

          if (bearOrCreppy === "bear") {
            return await buyBear(qtdMint, account)
          } else {
            return await buyCreepyKid(qtdMint, account)
          }

        } else {
          return {
            success: false,
            msg: "Please, enter a valid number from 0 to 20"
          };
        }
      }
    }
  }
  catch (e) {
    if (e.message && e.message.includes("insufficient funds")) {
      return {
        success: false,
        msg: "Insufficient Funds"
      };
    }

    if (e.message && e.message.includes("for the contract")) {
      return {
        success: false,
        msg: "Stay in Ethereum Mainnet Network"
      };
    }

    if (e.message && e.message.includes("User denied t")) {
      return {
        success: false,
        msg: "User denied transaction"
      };
    }

    if (e.message && e.message.includes("Sale has")) {
      return {
        success: false,
        msg: "Sale has not started"
      };
    }

    return {
      success: false,
      msg: "Something goes wrong"
    };
  }
};

const getAccount = async () => {
  const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
  return accounts[0];
}

const buyBear = async (qtdNft, account) => {
  const sale = new window.web3.eth.Contract(saleAbi, saleAddressBears)
  const price = await sale.methods.getCurrentPrice().call();
  const amount = Number(price) * Number(qtdNft)
  const method = sale.methods.mintNFT(Number(qtdNft));
  const gasEstimation = await method.estimateGas({
    from: account,
    value: amount,
  });

  return await method
    .send({
      from: account,
      gas: gasEstimation,
      value: amount,
    })
    .once("confirmation", async (res) => {
      return {
        success: true,
        msg: "Success"
      };
    });
}

const buyCreepyKid = async (qtdNft, account) => {
  const sale = new window.web3.eth.Contract(creepyRedemptionAbi, saleAddressCreepy)
  const eligibleNumberOfRedemptions = await numberOfEligibleEvilTeddyBearInAccount(account, qtdNft, sale)

  if (+eligibleNumberOfRedemptions !== +qtdNft) {
    return {
      success: false,
      msg: "It looks like you cannot claim this many Creepy Kids! Either you don't have enough eligible Evil Teddys or you already claimed a kid for your Teddy"
    };
  }

  const fee = await sale.methods.fee().call();
  const amount = Number(fee) * Number(qtdNft)
  const method = sale.methods.mintNFT(Number(qtdNft));
  const gasEstimation = await method.estimateGas({
    from: account,
    value: amount,
  });
  return await method
    .send({
      from: account,
      gas: gasEstimation,
      value: amount,
    })
    .once("confirmation", async (res) => {
      return {
        success: true,
        msg: "Success"
      };
    });
};

const numberOfEligibleEvilTeddyBearInAccount = async (account, qtdNft, contract) => {
  let num = "0";
  try {
    const res = await contract.methods.numberOfEligibleEvilTeddyBearInAccount(account, qtdNft).call();
    num = res.eTeddys
    console.log({num, qtdNft})
  }
  catch (e) {
  }
  return num;
};


export const totalSupply = async (contract) => {
  const urlInfura = "https://mainnet.infura.io/v3/449939b480d14c67b82e3c27c84571a0";
  const web3Local = new Web3(Web3.givenProvider || urlInfura);

  const contractConnected = new web3Local.eth.Contract(etbcAbi, contract);
  let totalSupply = "0";
  try {
    totalSupply = await contractConnected.methods.totalSupply().call();
  }
  catch (e) {
  }
  return totalSupply;
}

export const getNFTFromWallet = async () => {
  try {
    let account = await getAccount()

    let nfts = await getAllNfts(account)

    if (nfts.length === 0) {
      return []
    } else {

      let result = nfts
      if (result) {
        let arrayId = [];
        result.map(async (token) => {
          arrayId.push(token.token_id)
        })

        const statusConnected = await connectMetamask();
        if (!statusConnected.success) {
          return statusConnected;
        }

        let urlInfura =
          'https://mainnet.infura.io/v3/449939b480d14c67b82e3c27c84571a0'

        if (chainIdValid === 4) {
          urlInfura = 'https://rinkeby.infura.io/v3/bded3d2d413e4f13a18ec0cbafdedac9'
        }

        const web3Local = new Web3(Web3.givenProvider || urlInfura)

        const contractConnected = new web3Local.eth.Contract(
          redemptionAbi,
          redemption
        )

        let value = await contractConnected.methods.redeemInfo(arrayId).call();
        return {value: web3Local.utils.fromWei(value), ids: arrayId};

      }
      return 0;
    }
  }
  catch (e) {
    return []
  }
}

const getAllNfts = async (account) => {
  let config = {
    headers: {
      'X-API-Key':
        'E54VdXtFgwXLJasbGRginGxMZz3qwWoQoYW1qLz4soNopBXN1D4lOAvFTK74rN1p'
    }
  }

  let cursor = null;
  let nfts = [];

  for(let x=1; x<10; x++) {
    let request = await axios.get(
      'https://deep-index.moralis.io/api/v2/' +
      account +
      '/nft/' +
      _bearsContract +
      '?chain=' + chainIdValidName + '&format=decimal' +
      (cursor ? '&cursor=' + cursor : ''),
      config
    )

    nfts = [...nfts, ...request.data.result]
    if(!request.data.cursor){
      break;
    }
    cursor = request.data.cursor;
  }

  return nfts;
}


export const claim = async (nftsId) => {
  try {
    const statusConnected = await connectMetamask();
    if (!statusConnected.success) {
      return statusConnected;
    }

    let urlInfura =
      'https://mainnet.infura.io/v3/449939b480d14c67b82e3c27c84571a0'

    if (chainIdValid === 4) {
      urlInfura = 'https://rinkeby.infura.io/v3/bded3d2d413e4f13a18ec0cbafdedac9'
    }

    let account = await getAccount()
    const web3Local = new Web3(Web3.givenProvider || urlInfura)

    const contractConnected = new web3Local.eth.Contract(
      redemptionAbi,
      redemption
    )

    return await contractConnected.methods.redeem(nftsId).send({from: account})
  }
  catch (e) {
    return {
      success: false,
      msg: "Something goes wrong, make sure that your Teddy's have $EVIL to claim, if you are connected or if you are on Mainnet network"
    }
  }
}

export const getBalanceCoinUser = async () => {
  try {
    await connectMetamask();
    console.log('foi1')
    let urlInfura =
      'https://mainnet.infura.io/v3/449939b480d14c67b82e3c27c84571a0'

    if (chainIdValid === 4) {
      urlInfura = 'https://rinkeby.infura.io/v3/bded3d2d413e4f13a18ec0cbafdedac9'
    }

    let account = await getAccount()
    console.log('foi2')
    console.log(account)
    const web3Local = new Web3(Web3.givenProvider || urlInfura)

    const contractConnected = new web3Local.eth.Contract(coinAbi, coin)

    let value = await contractConnected.methods.balanceOf(account).call()
    return {success: true, value:parseInt(web3Local.utils.fromWei(value, 'ether'))}
  } catch (e) {
    console.log(e)
    return {success: false, value: 0};
  }
}

export const mintWithEvil = async (qtdNft) => {
  const statusConnected = await connectMetamask();
  if (!statusConnected.success) {
    return statusConnected;
  }

  let urlInfura =
    'https://mainnet.infura.io/v3/449939b480d14c67b82e3c27c84571a0'

  if (chainIdValid === 4) {
    urlInfura = 'https://rinkeby.infura.io/v3/bded3d2d413e4f13a18ec0cbafdedac9'
  }

  let account = await getAccount()
  const web3Local = new Web3(Web3.givenProvider || urlInfura)

  const contractCoin = new web3Local.eth.Contract(coinAbi, coin)

  let value = Web3.utils.fromWei(await contractCoin.methods.allowance(account, saleAddressBears2).call(), "ether")
  if (value < 1000) {
    try {
      await contractCoin.methods.approve(saleAddressBears2, "10000000000000000000000000000").send({"from": account});
    } catch (e) {
      console.log(e)
      return {success: false, msg: "You don't give permission to contract sale take the EVIL"}
    }
  }

  try {
    let sale = new window.web3.eth.Contract(sale2Abi, saleAddressBears2)
    let price = await sale.methods.calculateFee().call();
    let amount = Number(price);
    let method = sale.methods.mintWithEvilCoins(Number(qtdNft));
    let gasEstimation = await method.estimateGas({
      from: account,
      value: amount,
    });

    return await method
      .send({
        from: account,
        gas: gasEstimation,
        value: amount,
      })
      .once("confirmation", async (res) => {
        return {
          success: true,
          msg: "Success"
        };
      });
  } catch (e) {
    console.log(e)
    if (e.message && e.message.includes("Sale has")) {
      return {
        success: false,
        msg: "Sale has not started"
      };
    }
    return {success: false, msg: "Something goes wrong"}
  }
}
